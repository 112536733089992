$(function () {
    let $isScrolling = 0;
    let $timeoutId;
    //デバイス判定（タッチが有効か否か）
    let isTouchDevice = (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch);
    //デバイス判定によるイベントの決定
    let eventType = (isTouchDevice) ? 'touchend' : 'click';
    
    $(window).on('scroll', function() {
        $isScrolling = 1;
        
        // スクロールを停止して200ms後に終了とする
        clearTimeout($timeoutId);
        
        $timeoutId = setTimeout(function () {
            $isScrolling = 0;
        }, 200);
    });
    
    $('.hamburger-menu-content').on('scroll', function() {
        $isScrolling = 1;
        
        // スクロールを停止して200ms後に終了とする
        clearTimeout($timeoutId);
        
        $timeoutId = setTimeout(function () {
            $isScrolling = 0;
        }, 200);
    });
    
    // ダイアログを開く
    $('.dialog-open').on(eventType, function () {
        if ($isScrolling === 0) {
            let dialog_target = $(this).data('dialog-target');
            common.openDialog(dialog_target)
        }
    });
    
    // ダイアログを閉じる
    $('.dialog-close').on(eventType, function () {
        if ($isScrolling === 0) {
            common.closeDialog();
        }
    });
});
